import service1 from "../assets/images/service-1.png";
import shape1 from "../assets/images/shape/shape-1.svg";
import shape2 from "../assets/images/shape/shape-2.svg";
import service2 from "../assets/images/service-2.png";
import shape3 from "../assets/images/shape/shape-3.svg";
import service3 from "../assets/images/service-3.png";
import shape4 from "../assets/images/shape/shape-4.svg";
import shape5 from "../assets/images/shape/shape-5.svg";

const WhyWithUS = () => {
  return (
    <section id="service" className="service-area pt-75">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-8">
            <div
              className="section-title wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <h6 className="sub-title">Why Us</h6>
              <h4 className="title">
                The reasons to choose us <span>as your business partner</span>
              </h4>
            </div>
          </div>
        </div>
        <div
          className="service-wrapper mt-60 wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.6s"
        >
          <div className="row no-gutters justify-content-center">
            <div className="col-lg-4 col-md-7">
              <div className="single-service d-flex">
                <div className="service-icon">
                  <img src={service1} alt="Icon" />
                </div>
                <div className="service-content media-body">
                  <h4 className="service-title">Highly Experienced</h4>
                  <p className="text">
                  We have an expert team that specializes in UI/UX design, developing websites, mobile applications and digital marketing.
                  </p>
                </div>
                <div className="shape shape-1">
                  <img src={shape1} alt="shape" />
                </div>
                <div className="shape shape-2">
                  <img src={shape2} alt="shape" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-7">
              <div className="single-service service-border d-flex">
                <div className="service-icon">
                  <img src={service2} alt="Icon" />
                </div>
                <div className="service-content media-body">
                  <h4 className="service-title">Bunch of Services</h4>
                  <p className="text">
                  Providing one-stop solutions for all technology needs, we provide everything from software development and maintenance to system integration, consulting, and beyond.
                  </p>
                </div>
                <div className="shape shape-3">
                  <img src={shape3} alt="shape" />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-7">
              <div className="single-service d-flex">
                <div className="service-icon">
                  <img src={service3} alt="Icon" />
                </div>
                <div className="service-content media-body">
                  <h4 className="service-title">Quality Support</h4>
                  <p className="text">
                  Providing exceptional user experiences is our top priority through seamless communication, efficient bug resolution, and ongoing improvement.
                  </p>
                </div>
                <div className="shape shape-4">
                  <img src={shape4} alt="shape" />
                </div>
                <div className="shape shape-5">
                  <img src={shape5} alt="shape" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="service-btn text-center pt-25 pb-15">
                <a className="main-btn main-btn-2" href="#">
                  All Services
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyWithUS;
