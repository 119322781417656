import protfolio1 from "../assets/images/delightcode_protfolio-1.jpg";
import protfolio2 from "../assets/images/delightcode_protfolio-2.jpg";
import protfolio3 from "../assets/images/delightcode_protfolio-3.jpg";
const Portfolio = () => {
  return (
    <section id="portfolio" className="project-masonry-area pt-75">
      <div className="container">
        <div className="row align-items-end">
          <div className="col-lg-4">
            <div
              className="section-title pb-20  wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.4s"
            >
              <h6 className="sub-title">Portfolio</h6>
              <h4 className="title">
                Portfolio <span>Gallery.</span>
              </h4>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="project-menu text-center text-sm-left text-lg-right pb-20">
              <ul>
                <li className="active" data-filter="*">
                  See All
                </li>
                <li data-filter=".apps">Apps</li>
                <li data-filter=".websites">WebSites</li>
               
              </ul>
            </div>
          </div>
        </div>
        <div className="row grid">
          <div className="col-lg-4 col-sm-6 grid-item apps creative laptop">
            <div className="single-gallery gallery-masonry mt-30">
              <div className="gallery-image">
                <img src={protfolio1} alt="protfolio" />
              </div>
              <div className="gallery-icon">
                <a className="image-popup" href={protfolio1}>
                  <span></span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 grid-item apps branding product">
            <div className="single-gallery gallery-masonry mt-30">
              <div className="gallery-image">
                <img src={protfolio2} alt="protfolio" />
              </div>
              <div className="gallery-icon">
                <a className="image-popup" href={protfolio2}>
                  <span></span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-sm-6 grid-item laptop product">
            <div className="single-gallery gallery-masonry mt-30">
              <div className="gallery-image">
                <img src={protfolio3} alt="protfolio" />
              </div>
              <div className="gallery-icon">
                <a className="image-popup" href={protfolio3}>
                  <span></span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="gallery-btn mt-60 text-center">
              <a className="main-btn" href="#">
                Load More
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
