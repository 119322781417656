import Slider from "react-slick";
import author1 from "../assets/images/delightcode_author-1.jpg";
const Testimonial = () => {
  const data = [
    {
      authorImage: author1,
      authorThought:
        "It is my pleasure to share my experience with DelightCode Infotech Website Development service. The team at this company is very talented and creative, and they approach their work in a professional manner.",
      authorName: "APS.",
      authorCountry: "From Sierra Leone",
      authorRating: 5,
    },
    {
      authorImage: author1,
      authorThought:
        "Throughout the entire development process, DelightCode Infotech demonstrated an understanding of our vision and goals. The entire team was cooperative with our feedback, making necessary adjustments promptly.",
      authorName: "Instinct Joe",
      authorCountry: "United States",
      authorRating: 5,
    },
    {
      authorImage: author1,
      authorThought:
        "DelightCode Infotech helped me create my Website which immediately boosted my online presence and attracted online users to my business. The Design, Development, and Content Writing services were up to the mark.",
      authorName: "Aman",
      authorCountry: "From India",
      authorRating: 5,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    autoplay: false,
    speed: 800,
    cssEase: "linear",
    nextArrow: (
      <span className="prev">
        <i className="lni-arrow-left"></i>
      </span>
    ),
    prevArrow: (
      <span className="next">
        <i className="lni-arrow-right ml-5"></i>
      </span>
    ),
  };
  return (
    <section id="testimonial" className="testimonial-area pt-75 pb-120">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-xl-5 col-lg-6">
            <div
              className="testimonial-left-content mt-45 wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <div className="section-title">
                <h6 className="sub-title">Testimonials</h6>
                <h4 className="title">What Client Says, About Us</h4>
              </div>
              <ul className="testimonial-line">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
              <p className="text">
                Many time, I have worked with DelightCode Infotech, and I have
                been more impressed with their services. It has been a pleasure
                working with their team, from the initial consultation through
                the final product delivery. As a result, they developed an
                innovative software solution that exceeded not only our
                expectations but also met our specific needs.{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-6">
            <div
              className="testimonial-right-content mt-50 wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.6s"
            >
              <div className="quota">
                <i className="lni-quotation"></i>
              </div>
              <div className="testimonial-content-wrapper testimonial-active">
                <Slider {...settings}>
                  {data.map((data, index) => {
                    return (
                      <div className="single-testimonial" key={index}>
                        <div className="testimonial-text">
                          <p className="text">“{data.authorThought}”</p>
                        </div>
                        <div className="testimonial-author d-sm-flex justify-content-between">
                          <div className="author-info d-flex align-items-center">
                            <div className="author-image">
                              <img src={data.authorImage} alt="author" />
                            </div>
                            <div className="author-name media-body">
                              <h5 className="name">{data.authorName}</h5>
                              <span className="sub-title">
                                From {data.authorCountry}
                              </span>
                            </div>
                          </div>
                          <div className="author-review">
                            <ul className="star">
                              <li>
                                <i className="lni-star"></i>
                              </li>
                              <li>
                                <i className="lni-star"></i>
                              </li>
                              <li>
                                <i className="lni-star"></i>
                              </li>
                              <li>
                                <i className="lni-star"></i>
                              </li>
                              <li>
                                <i className="lni-star"></i>
                              </li>
                            </ul>
                            <span className="review">
                              ( ${data.authorRating} Reviews )
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
                {/* <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p className="text">
                      “It is my pleasure to share my experience with DelightCode
                      Infotech Website Development service. I have had a
                      wonderful experience working with them from beginning to
                      end. The team at this company is very talented and
                      creative, and they approach their work in a professional
                      manner.”
                    </p>
                  </div>
                  <div className="testimonial-author d-sm-flex justify-content-between">
                    <div className="author-info d-flex align-items-center">
                      <div className="author-image">
                        <img src={author1} alt="author" />
                      </div>
                      <div className="author-name media-body">
                        <h5 className="name">APS</h5>
                        <span className="sub-title">From Sierra Leone</span>
                      </div>
                    </div>
                    <div className="author-review">
                      <ul className="star">
                        <li>
                          <i className="lni-star"></i>
                        </li>
                        <li>
                          <i className="lni-star"></i>
                        </li>
                        <li>
                          <i className="lni-star"></i>
                        </li>
                        <li>
                          <i className="lni-star"></i>
                        </li>
                        <li>
                          <i className="lni-star"></i>
                        </li>
                      </ul>
                      <span className="review">( 7 Reviews )</span>
                    </div>
                  </div>
                </div>
                <div className="single-testimonial">
                  <div className="testimonial-text">
                    <p className="text">
                      “DelightCode Infotech helped me create my Website which
                      immediately boosted my online presence and attracted
                      online users to my business. The Design, Development, and
                      Content Writing services were up to the mark.”
                    </p>
                  </div>
                  <div className="testimonial-author d-sm-flex justify-content-between">
                    <div className="author-info d-flex align-items-center">
                      <div className="author-image">
                        <img src={author1} alt="author" />
                      </div>
                      <div className="author-name media-body">
                        <h5 className="name">Aman</h5>
                        <span className="sub-title">From India</span>
                      </div>
                    </div>
                    <div className="author-review">
                      <ul className="star">
                        <li>
                          <i className="lni-star"></i>
                        </li>
                        <li>
                          <i className="lni-star"></i>
                        </li>
                        <li>
                          <i className="lni-star"></i>
                        </li>
                        <li>
                          <i className="lni-star"></i>
                        </li>
                        <li>
                          <i className="lni-star"></i>
                        </li>
                      </ul>
                      <span className="review">( 3 Reviews )</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
