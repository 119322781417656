import mobileService from "../assets/images/mobile_development_delightcode_infotech.svg";
import webService from "../assets/images/web_application_development_delightcode_infotech.svg";
import uiuxService from "../assets/images/uiux_designing_delightcode_infotech.svg";
import digitalService from "../assets/images/digital_marketing_delightcode_infotech.svg";
import { CircularProgressbar } from "react-circular-progressbar";
const Services = () => {
  return (
    <section id="services" className="our-services-area pt-75">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-lg-6 col-md-8 col-sm-9">
            <div
              className="section-title text-center wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <h6 className="sub-title">Our services</h6>
              <h4 className="title">
                Bunch of Services <span>to Rock Your Business</span>
              </h4>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="our-services-tab pt-30">
              <ul
                className="nav justify-content-center wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
                id="myTab"
                role="tablist"
              >
                <li className="nav-item">
                  <a
                    className="active"
                    id="delight-code-mobile-tab"
                    data-toggle="tab"
                    href="#mobile"
                    role="tab"
                    aria-controls="digital"
                    aria-selected="false"
                  >
                    <i className="lni lni-mobile"></i>{" "}
                    <span>
                      Mobile App <br /> Development
                    </span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    id="digital-tab"
                    data-toggle="tab"
                    href="#web"
                    role="tab"
                    aria-controls="digital"
                    aria-selected="false"
                  >
                    <i className="lni-bullhorn"></i>{" "}
                    <span>
                      Web <br /> Development
                    </span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    id="business-tab"
                    data-toggle="tab"
                    href="#uiux"
                    role="tab"
                    aria-controls="business"
                    aria-selected="true"
                  >
                    <i className="lni-briefcase"></i>{" "}
                    <span>
                      UI/UX <br /> Design
                    </span>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    id="digital-tab"
                    data-toggle="tab"
                    href="#digital"
                    role="tab"
                    aria-controls="digital"
                    aria-selected="false"
                  >
                    <i className="lni-bullhorn"></i>{" "}
                    <span>
                      Digital <br /> Marketing
                    </span>
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="mobile"
                  role="tabpanel"
                  aria-labelledby="delight-code-mobile-tab"
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="our-services-image mt-50">
                        <img
                          src={mobileService}
                          alt="delightcode infotech mobile service"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="our-services-content mt-45">
                        <h3 className="services-title">
                          Mobile Development{" "}
                          <span>for Your Business Growth.</span>
                        </h3>
                        <p className="text">
                          It is our specialty to design and develop fully
                          customized mobile applications using a wide range of
                          platforms including Android, iOS, Flutter, React
                          Native, Xamarin, or any other platform you might
                          choose.{" "}
                        </p>

                        <div className="our-services-progress d-flex align-items-center mt-55">
                          <div style={{ width: 100, height: 100 }}>
                            <CircularProgressbar
                              value={95}
                              text={`${95}%`}
                              strokeWidth={3}
                              styles={{
                                // Customize the root svg element
                                root: {},
                                // Customize the path, i.e. the "completed progress"
                                path: {
                                  // Path color
                                  stroke: "#f14836",
                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                  strokeLinecap: "butt",
                                  // Customize transition animation
                                  transition: "stroke-dashoffset 0.5s ease 0s",
                                  // Rotate the path
                                  transform: "rotate(1turn)",
                                  transformOrigin: "center center",
                                },
                                // Customize the circle behind the path, i.e. the "total progress"
                                trail: {
                                  // Trail color
                                  stroke: "#d6d6d6",
                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                  strokeLinecap: "butt",
                                  // Rotate the trail
                                  transform: "rotate(0.75turn)",
                                  transformOrigin: "center center",
                                },
                                // Customize the text
                                text: {
                                  // Text color
                                  fill: "#222",
                                  // Text size
                                  fontSize: "23px",
                                  fontWeight: 700,
                                },
                                // Customize background - only used when the `background` prop is true
                                background: {
                                  fill: "#3e98c7",
                                },
                              }}
                            />
                          </div>

                          <div className="progress-content">
                            <h4 className="progress-title">
                              Mobile Development
                              <br /> Skill.
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade show"
                  id="web"
                  role="tabpanel"
                  aria-labelledby="business-tab"
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="our-services-image mt-50">
                        <img src={webService} alt="service" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="our-services-content mt-45">
                        <h3 className="services-title">
                          Web Development <span>for Your Business Growth.</span>
                        </h3>
                        <p className="text">
                          It is our objective to provide our clients with the
                          best web development services. Our open-source
                          developers are experienced and professional in the
                          field of web development, and are capable of creating
                          customized, functional, and robust web development
                          solutions that are tailored to meet their
                          requirements.{" "}
                        </p>

                        <div className="our-services-progress d-flex align-items-center mt-55">
                          <div style={{ width: 100, height: 100 }}>
                            <CircularProgressbar
                              value={97}
                              text={`${97}%`}
                              strokeWidth={3}
                              styles={{
                                // Customize the root svg element
                                root: {},
                                // Customize the path, i.e. the "completed progress"
                                path: {
                                  // Path color
                                  stroke: "#f14836",
                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                  strokeLinecap: "butt",
                                  // Customize transition animation
                                  transition: "stroke-dashoffset 0.5s ease 0s",
                                  // Rotate the path
                                  transform: "rotate(1turn)",
                                  transformOrigin: "center center",
                                },
                                // Customize the circle behind the path, i.e. the "total progress"
                                trail: {
                                  // Trail color
                                  stroke: "#d6d6d6",
                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                  strokeLinecap: "butt",
                                  // Rotate the trail
                                  transform: "rotate(0.75turn)",
                                  transformOrigin: "center center",
                                },
                                // Customize the text
                                text: {
                                  // Text color
                                  fill: "#222",
                                  // Text size
                                  fontSize: "23px",
                                  fontWeight: 700,
                                },
                                // Customize background - only used when the `background` prop is true
                                background: {
                                  fill: "#3e98c7",
                                },
                              }}
                            />
                          </div>
                          <div className="progress-content">
                            <h4 className="progress-title">
                              Web Development
                              <br /> Skill.
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="uiux"
                  role="tabpanel"
                  aria-labelledby="digital-tab"
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="our-services-image mt-50">
                        <img src={uiuxService} alt="service" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="our-services-content mt-45">
                        <h3 className="services-title">
                          UI/UX Design <span>for Your Business Growth.</span>
                        </h3>
                        <p className="text">
                          It is our specialty to create innovative user
                          interfaces and experiences for our clients. There is a
                          wide variety of projects we have completed ranging
                          from mobile applications and websites in our
                          portfolio.{" "}
                        </p>

                        <div className="our-services-progress d-flex align-items-center mt-55">
                          {/* <div className="circle" id="circles-2"></div> */}
                          <div style={{ width: 100, height: 100 }}>
                            <CircularProgressbar
                              value={95}
                              text={`${95}%`}
                              strokeWidth={3}
                              styles={{
                                // Customize the root svg element
                                root: {},
                                // Customize the path, i.e. the "completed progress"
                                path: {
                                  // Path color
                                  stroke: "#f14836",
                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                  strokeLinecap: "butt",
                                  // Customize transition animation
                                  transition: "stroke-dashoffset 0.5s ease 0s",
                                  // Rotate the path
                                  transform: "rotate(1turn)",
                                  transformOrigin: "center center",
                                },
                                // Customize the circle behind the path, i.e. the "total progress"
                                trail: {
                                  // Trail color
                                  stroke: "#d6d6d6",
                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                  strokeLinecap: "butt",
                                  // Rotate the trail
                                  transform: "rotate(0.75turn)",
                                  transformOrigin: "center center",
                                },
                                // Customize the text
                                text: {
                                  // Text color
                                  fill: "#222",
                                  // Text size
                                  fontSize: "23px",
                                  fontWeight: 700,
                                },
                                // Customize background - only used when the `background` prop is true
                                background: {
                                  fill: "#3e98c7",
                                },
                              }}
                            />
                          </div>
                          <div className="progress-content">
                            <h4 className="progress-title">
                              UI/UX Design <br /> Design.
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="digital"
                  role="tabpanel"
                  aria-labelledby="digital-tab"
                >
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="our-services-image mt-50">
                        <img src={digitalService} alt="service" />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="our-services-content mt-45">
                        <h3 className="services-title">
                          Digital Marketing{" "}
                          <span>for Your Business Growth.</span>
                        </h3>
                        <p className="text">
                          It is our goal to provide you with digital marketing
                          solutions that will accelerate the growth of your
                          business. We have a very experienced team of marketing
                          professionals with extensive marketing and advertising
                          experience on our side.{" "}
                        </p>

                        <div className="our-services-progress d-flex align-items-center mt-55">
                          <div style={{ width: 100, height: 100 }}>
                            <CircularProgressbar
                              value={90}
                              text={`${90}%`}
                              strokeWidth={3}
                              styles={{
                                // Customize the root svg element
                                root: {},
                                // Customize the path, i.e. the "completed progress"
                                path: {
                                  // Path color
                                  stroke: "#f14836",
                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                  strokeLinecap: "butt",
                                  // Customize transition animation
                                  transition: "stroke-dashoffset 0.5s ease 0s",
                                  // Rotate the path
                                  transform: "rotate(1turn)",
                                  transformOrigin: "center center",
                                },
                                // Customize the circle behind the path, i.e. the "total progress"
                                trail: {
                                  // Trail color
                                  stroke: "#d6d6d6",
                                  // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                  strokeLinecap: "butt",
                                  // Rotate the trail
                                  transform: "rotate(0.75turn)",
                                  transformOrigin: "center center",
                                },
                                // Customize the text
                                text: {
                                  // Text color
                                  fill: "#222",
                                  // Text size
                                  fontSize: "23px",
                                  fontWeight: 700,
                                },
                                // Customize background - only used when the `background` prop is true
                                background: {
                                  fill: "#3e98c7",
                                },
                              }}
                            />
                          </div>
                          <div className="progress-content">
                            <h4 className="progress-title">
                              Digital Marketing <br /> Skill.
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
