//import heroImage from "../assets/images/hero-image-free-3.svg";
import heroImage from "../assets/images/delight_code_infotech_compnay.svg";
import heroBackground from "../assets/images/header-hero.jpg";

function Home() {
  return (
    <div
      id="home"
      className="header-hero bg_cover d-lg-flex align-items-center"
      style={{ backgroundImage: `url(${heroBackground})` }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <div className="header-hero-content">
              <h1
                className="hero-title wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <b>Your</b> <span>Delighted</span>
                <br /> Partner for <b>Growth.</b>
              </h1>
              <p
                className="text wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="0.5s"
              >
                Let's collaborate to bring your vision to life and leave you
                delighted with the results.
              </p>
              <div
                className="header-singup wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="0.8s"
              >
                <input type="text" placeholder="username@yourdomain.com" />
                <button className="main-btn">Get Estimate</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="header-hero-image d-flex align-items-center wow fadeInRightBig"
        data-wow-duration="1s"
        data-wow-delay="1.1s"
      >
        <div className="image">
          <img src={heroImage} alt="Home Delight Code Infotech Company Banner"/>
        </div>
      </div>
    </div>
  );
}

export default Home;
