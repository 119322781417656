// import logo from "../assets/images/logo-1.png";
import logo from "../assets/images/delight_code_logo.png";
const Header = () => {
  return (
    <div className="navbar-area headroom">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <nav className="navbar navbar-expand-lg">
              <a className="navbar-brand" href="/">
                <img src={logo} alt="Logo" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="toggler-icon"></span>
                <span className="toggler-icon"></span>
                <span className="toggler-icon"></span>
              </button>
              <div
                className="collapse navbar-collapse sub-menu-bar"
                id="navbarSupportedContent"
              >
                <ul id="nav" className="navbar-nav m-auto">
                  <li className="nav-item active">
                    <a href="#home">Home</a>
                  </li>
                  <li className="nav-item">
                    <a href="#about">About </a>
                  </li>
                  <li className="nav-item">
                    <a href="#services">Services</a>
                  </li>
                  <li className="nav-item">
                    <a href="#portfolio">Portfolio</a>
                  </li>
                  {/* <li className="nav-item">
                    <a href="#blog">Blog</a>
                  </li> */}
                  <li className="nav-item">
                    <a href="#contact">Contact</a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
