// import about from "../assets/images/about-free.svg";
import about from "../assets/images/delightcode_infotech_service.svg";
const About = () => {
  return (
    <section id="about" className="about-area pt-25">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <div
              className="about-title text-center wow fadeInUp"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <h6 className="welcome">WELCOME</h6>
              <h3 className="title">
                <span>Our 5 years working experience to </span> take care of
                your business goal.
              </h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div
              className="text-center about-image mt-60 wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              <img src={about} alt="about" />
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="about-content pt-45">
              <p
                className="text wow fadeInUp"
                data-wow-duration="1s"
                data-wow-delay="0.4s"
              >
                We're committed to crafting bespoke technology that delights our
                clients. With expertise in software, web, and mobile
                development, as well as AI and machine learning, we exceed
                expectations at every turn. Let's collaborate to bring your
                vision to life and leave you delighted with the results.
              </p>
              <div className="about-counter pt-30">
                <div className="row">
                  <div className="col-sm-4">
                    <div
                      className="single-counter counter-color-1 mt-30 d-flex wow fadeInUp"
                      data-wow-duration="1s"
                      data-wow-delay="0.3s"
                    >
                      <div className="counter-shape">
                        <span className="shape-1"></span>
                        <span className="shape-2"></span>
                      </div>
                      <div className="counter-content media-body">
                        <span className="counter-count">
                          <span className="counter">250</span>+
                        </span>
                        <p className="text">Clients</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className="single-counter counter-color-2 mt-30 d-flex wow fadeInUp"
                      data-wow-duration="1s"
                      data-wow-delay="0.6s"
                    >
                      <div className="counter-shape">
                        <span className="shape-1"></span>
                        <span className="shape-2"></span>
                      </div>
                      <div className="counter-content media-body">
                        <span className="counter-count">
                          <span className="counter">98</span>%
                        </span>
                        <p className="text">Satisfaction</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div
                      className="single-counter counter-color-3 mt-30 d-flex wow fadeInUp"
                      data-wow-duration="1s"
                      data-wow-delay="0.9s"
                    >
                      <div className="counter-shape">
                        <span className="shape-1"></span>
                        <span className="shape-2"></span>
                      </div>
                      <div className="counter-content media-body">
                        <span className="counter-count">
                          <span className="counter">500</span>+
                        </span>
                        <p className="text">Projects</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
