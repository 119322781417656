import logo from "../assets/images/delight_code_logo.png";
const Footer = () => {
  return (
    <footer
      id="footer"
      className="footer-area bg_cover"
      style={{ backgroundImage: "url(assets/images/footer-bg.jpg)" }}
    >
      <div className="container">
        <div className="footer-widget pt-30 pb-70">
          <div className="row">
            <div className="col-lg-3 col-sm-6 order-sm-1 order-lg-1">
              <div className="footer-about pt-40">
                <a href="#">
                  <img src={logo} alt="Logo" />
                </a>
                <p className="text">
                Let's collaborate to bring your vision to life and leave you delighted with the results. </p>{" "}
                
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 order-sm-3 order-lg-2">
              <div className="footer-link pt-40">
                <div className="footer-title">
                  <h5 className="title">Services</h5>
                </div>
                <ul>
                  <li>
                    <a href="#">Web Development</a>
                  </li>
                  <li>
                    <a href="#">Mobile Development</a>
                  </li>
                  <li>
                    <a href="#">UI/UX Design</a>
                  </li>
                  <li>
                    <a href="#">Digital Marketing</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 order-sm-4 order-lg-3">
              <div className="footer-link pt-40">
                <div className="footer-title">
                  <h5 className="title">About Us</h5>
                </div>
                <ul>
                  <li>
                    <a href="#">Overview</a>
                  </li>
                  <li>
                    <a href="#">Why us</a>
                  </li>
                  <li>
                    <a href="#">Awards & Recognitions</a>
                  </li>
                  <li>
                    <a href="#">Team</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 order-sm-2 order-lg-4">
              <div className="footer-contact pt-40">
                <div className="footer-title">
                  <h5 className="title">Contact Info</h5>
                </div>
                <div className="contact pt-10">
                  <p className="text">
                    512 The Business Galleria 2,
                    <br />
                    Yogi Chowk, Chikuwadi, Surat Gujarat.
                  </p>
                  {/* <p className="text">support@uideck.com</p>
                <p className="text">+99 000 555 66 22</p> */}

                  <ul className="social mt-40">
                    <li>
                      <a href="#">
                        <i className="lni-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="lni-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="lni-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/delightcodeinfotech/">
                        <i className="lni-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-copyright text-center">
          <p className="text">
            © {new Date().getFullYear()} Crafted by{" "}
            <a href="/" rel="nofollow">
              Delightcode
            </a>{" "}
            All Rights Reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
