import About from "./about";
import Contact from "./contact";
import Footer from "./footer";
import Header from "./header";
import Home from "./home";
import Portfolio from "./portfolio";
import Services from "./services";
import Testimonial from "./testimonial";
import WhyWithUS from "./whyWithUs";

function Main() {
  return (
    <div className="main">
      <header className="header-area">
        <Header />
        <Home />
      </header>

      <About />

      <Services />

      <WhyWithUS />

      <Portfolio />

      {/* <Pricing /> */}

      <Testimonial />

      {/* <Brands />

      <Blogs /> */}

      <Contact />

      <Footer />

      <a href="#" className="back-to-top">
        <i className="lni-chevron-up"></i>
      </a>
    </div>
  );
}

export default Main;
